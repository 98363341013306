import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import { Admin, Resource } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { createBrowserHistory as createHistory } from 'history';
import defaultMessages from 'ra-language-english';
import Layout from 'Layout/Layout';
import createStore from 'store';
import authProvider from 'AuthProvider';
import buildProvider from 'DataProvider';

import Administrators from 'Administrators';
import OrganisationGroups from 'OrganisationGroups';

import Activities from 'Activities';
import Conditions from 'Conditions';
import Storefronts from 'Storefronts';

import loginPage from 'Login';
import routes from 'Routes';

const history = createHistory();
const stage = '';
const commit = '';

class App extends Component {
  constructor() {
    super();
    this.state = { store: null };
  }

  componentDidMount() {
    buildProvider().then(dataProvider => {
      const store = createStore({
        authProvider,
        dataProvider,
        history,
        i18nProvider: () => ({
          ...defaultMessages,
          // Fix annoying error in console.
          Analytics: 'Analytics',
        }),
      });
      window.__getStore = () => store;
      this.setState({ store });
    });
  }

  renderAdminResources(permissions) {
    return [
      <Resource name="activities" {...Activities} options={{ label: 'Activities' }} />,
      <Resource name="conditions" {...Conditions} options={{ label: 'Conditions' }} />,
      <Resource name="storefronts" {...Storefronts} options={{ label: 'Storefronts' }} />,
      
      // Hidden from menu
      <Resource name="admin" {...Administrators(permissions)} options={{ label: 'Administrators', hidden: true }} />,
      <Resource name="group" {...OrganisationGroups} options={{ label: 'Groups', hidden: true }} />,
      <Resource name="OrganisationPackage" options={{ hidden: true }} />,
      <Resource name="ApiType" options={{ hidden: true }} />,
      <Resource name="ActivityCategory" options={{ hidden: true }} />,
      <Resource name="goals" options={{ hidden: true }} />,
    ];
  }

  renderClinicianResources(permissions) {
    return [
      // Hidden from menu
      <Resource name="admin" {...Administrators(permissions)} options={{ label: 'Administrators', hidden: true }} />,
      <Resource name="group" {...OrganisationGroups} options={{ label: 'Groups', hidden: true }} />,
      <Resource name="OrganisationPackage" options={{ hidden: true }} />,
      <Resource name="ApiType" options={{ hidden: true }} />,
      <Resource name="ActivityCategory" options={{ hidden: true }} />,
      <Resource name="goals" options={{ hidden: true }} />,
    ];
  }

  renderHealthAuthorityResources(permissions) {
    return [
      // Hidden from menu
      <Resource name="OrganisationPackage" options={{ hidden: true }} />,
      <Resource name="ApiType" options={{ hidden: true }} />,
      <Resource name="group" {...OrganisationGroups} options={{ label: 'Groups', hidden: true }} />,
      <Resource name="ActivityCategory" options={{ hidden: true }} />,
      <Resource name="admin" {...Administrators(permissions)} options={{ label: 'Administrators', hidden: true }} />,
      <Resource name="goals" options={{ hidden: true }} />,
    ];
  }

  render() {
    const { store } = this.state;

    if (!store) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '10%',
            marginLeft: '50%',
          }}
        >
          {'Loading App'}
          <CircularProgress size={60} style={{ color: 'rgb(60, 70, 87)' }} />
        </div>
      );
    }

    return (
      <Provider store={store}>
        <Fragment>
          <Admin
            history={history}
            title="EXi"
            appLayout={Layout}
            loginPage={loginPage}
            customRoutes={routes}
            authProvider={authProvider}
          >
            {permissions => {
              if (permissions.includes('SUPER')) {
                return this.renderAdminResources(permissions);
              }

              // Default ...
              return null;
            }}
          </Admin>
          {/* footer to display branch and commit id in Amplify for test purposes */}
          {stage &&
          !(
            stage.startsWith('prod') ||
            stage === 'master' ||
            stage === 'client-staging'
          ) ? (
            <div
              style={{
                position: 'sticky',
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 0,
                padding: 6,
                backgroundColor: '#efefef',
                textAlign: 'center',
              }}
            >
              {stage} {commit.substring(0, 4)}
            </div>
          ) : null}
        </Fragment>
      </Provider>
    );
  }
}

export default App;
