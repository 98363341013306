export const intensityMapping = [
  { id: 'LOW', name: 'Low' },
  { id: 'MEDIUM', name: 'Moderate' },
  { id: 'HIGH', name: 'High' },
];

export const statusMapping = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'DRAFT', name: 'Draft' },
  { id: 'HIDDEN', name: 'Hidden' },
];

export const trackingTypeMapping = [
  { id: 'DEFAULT', name: 'Heart Rate, Time' },
  { id: 'ROUTE', name: 'Distance, GPS, Heart Rate, Time' },
];

export const mediaTypeMapping = [
  { id: 'VIDEO', name: 'Video' },
  { id: 'AUDIO', name: 'Audio' },
];

export const mapValue = (map, value) => {
  const mapping = map.find(element => element.id === value);
  if (mapping) {
    return mapping.name;
  }
  return value;
};
