import gql from 'graphql-tag';

const contentType = file => file.type;

const requestPutUrl = async client => {
  const response = await client.mutate({
    mutation: gql`
      mutation CreateActivityImagePutUrl {
        url: createActivityImagePutUrl
      }
    `,
  });
  return response.data.url;
};

const putFile = (url, file) => {
  return fetch(url, {
    headers: { 'Content-Type': contentType(file) },
    body: file,
    method: 'PUT',
  });
};

const removeDomain = url => {
  return url.replace(/^.*?amazonaws.com\//, '');
};

const removePath = url => {
  const pathArr = url.split('/');
  return pathArr[pathArr.length - 1];
};

const removeQuery = url => {
  return url.replace(/\?.*/, '');
};

const getKey = url => removePath(removeDomain(removeQuery(url)));

const uploadImage = async ({ client, file }) => {
  const url = await requestPutUrl(client);
  await putFile(url, file);

  return getKey(url);
};

export default uploadImage;
