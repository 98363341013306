export const streamMapping = [
  { id: 'STANDARD', name: 'Standard' },
  { id: 'FIBROMYALGIA', name: 'Fibromyalgia' },
  {
    id: 'PERIPHERAL_ARTERIAL_DISEASE',
    name: 'Peripheral Arterial Disease',
  },
];

export const intensityMapping = [
  { id: 'MEDIUM', name: 'Moderate' },
  { id: 'HIGH', name: 'High' },
];

export const statusMapping = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'DRAFT', name: 'Draft' },
];

export const conditionBaselineMapping = [
  {
    id: 'WEIGHT',
    name: 'Weight Input',
  },
  {
    id: 'WAIST_CIRCUMFERENCE',
    name: 'Waist Circumference',
  },
  {
    id: 'BLOOD_PRESSURE',
    name: 'Blood Pressure',
  },
  {
    id: 'PAIN',
    name: 'Pain',
  },
  {
    id: 'JOINT_STIFFNESS',
    name: 'Joint Stiffness',
  },
  {
    id: 'MOOD',
    name: 'Mood',
  },
  {
    id: 'ENERGY',
    name: 'Energy',
  },
  {
    id: 'LUNG_FUNCTION',
    name: 'Lung Function',
  },
  {
    id: 'BLOOD_SUGAR',
    name: 'Blood Sugar',
  },
];

export const mapValue = (map, value) => {
  const mapping = map.find(element => element.id === value);
  if (mapping) {
    return mapping.name;
  }
  return value;
};
